import { Injectable } from '@angular/core';
import { HttpClient } from '../../../shared/HttpClient';
import { Observable } from 'rxjs/Observable';
//import * as Globals from "../../shared/globals";
import { OrderHistoryModel } from '../Model/orderhistory.model';
import { ConfigService } from '../../../config/ConfigService';

@Injectable()
export class OrderHistoryService {
    httpClient: HttpClient;
    
    apiUrl:any;
    constructor( httpClient: HttpClient, config: ConfigService) {
        this.httpClient = httpClient;
        this.apiUrl = config.get('apiUrl');
    }
    getProductOrderAdditionalData(searchData: any): any {
        return this.httpClient.eComPost('api/productOrderQuery/getOrderHistoryDataDetails', JSON.stringify(searchData)).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    } 

    getOrderNumber():any {
        return this.httpClient.eComGet('api/productOrderQuery/getOrderNumber').map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }
  
    getOrderHistoryData(searchData:any):any {
        return this.httpClient.eComPost('api/productOrderQuery/getOrderHistoryData', JSON.stringify(searchData)).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }
   
    getCancelData(orderId:any):any {
        return this.httpClient.eComGet('api/productOrderQuery/cancelData?orderId=' + orderId).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }
    downloadOrderHistoryData(OrderType: any , userId:any) {
        return this.httpClient.eComGetWithResponseTypeBlob('api/productOrderQuery/downloadOrderHistoryData?orderType=' + OrderType +'&userId='+ userId)
            .map(responsedata => {
                if (responsedata.status == 400) {
                    return "FAILURE";
                } else if (responsedata.status == 200) {
                    var contentType = 'application/pdf';
                    var blob = new Blob([(<any>responsedata)._body], { type: contentType });
                    return blob;
                }
            });

    }
    
}
