import { CartItem } from "../../ProductDetails/Model/productdetails.model";
import { DeliveryAddressModel } from "../../DeliveryAddress/Model/deliveryaddress.model";

export class OrderConfirmModel
{   orderKey:any;
    orderNumber : string;
   
    selectedStudyId : string;
    selectedSiteId :any;
    lineItems : LineItem[];
    selectedAddress : DeliveryAddressModel;
    comments : string;
    showPrice: boolean;
    
    public constructor(init: OrderConfirmModel) {
        Object.assign(this, init);
    }


}

export class LineItem
{
    
    productKey : string;
    productPrice : number;
    quantity : number;
    categoryName:string;
    categoryId: string;
    public constructor(init: LineItem) {
        Object.assign(this, init);
    }
}