import { Http, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'
import { Injectable } from '@angular/core';

import { config } from 'rxjs';
import { ConfigService } from 'src/app/config/ConfigService';
import { SessionService } from 'src/app/shared/services/session.service';
import { RequestOptions } from '@angular/http';
import { HttpClient } from 'src/app/shared/HttpClient';

@Injectable()
export class OtherService {

    httpClient: HttpClient;
    uploadImgUrl: any;
    apiurl:any;
    constructor(httpClient: HttpClient,config: ConfigService,private sessionService:SessionService) {
        this.httpClient = httpClient;
     
     
    }

    detailOther(searchData: any): any {
        // alert(analysisFeesId);
        return this.httpClient.ezMoneyPost('api/StudyQuoteSpecimenQuery/getStudyQuoteOtherItemMapView', JSON.stringify(searchData)).map(responsedata => {
                let body = responsedata.json();
                return body || {};
            });
    }
    getOtherGridData(searchData: any): any {
        return this.httpClient.ezMoneyPost('api/StudyQuoteSpecimenQuery/getOtherGridData', JSON.stringify(searchData)).map(responsedata => {
                let body = responsedata.json();
                return body || {};
            });
    }
     
    getCurrencyList(): any {
        return this.httpClient.ezMoneyGet('api/StudyInvoiceQuery/GetContractCurrencyList').map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }

    getRecurrenceType(): any {
        return this.httpClient.ezMoneyGet('api/StudyInvoiceQuery/GetRecurrenceType').map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }
    getExecutingLabs():any {
        return this.httpClient.ezMoneyGet('api/StudyInvoiceQuery/getAllExecutingLabs' ).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }  
    
    addStstrUp(searchData: any): any {
        // alert(analysisFeesId);
        return this.httpClient.ezMoneyPost('api/StudyQuoteSpecimenQuery/addStstrUp', JSON.stringify(searchData)).map(responsedata => {
                let body = responsedata.json();
                return body || {};
            });
    }
    deleteRecords(modelData: any): any {
        // alert(analysisFeesId);
        return this.httpClient.ezMoneyPost('api/StudyQuoteSpecimenQuery/deleteRecords', JSON.stringify(modelData)).map(responsedata => {
                let body = responsedata.json();
                return body || {};
            });
    }
    saveRecords(modelData: any): any{
        return this.httpClient.ezMoneyPost('api/StudyQuoteSpecimenQuery/saveRecords', JSON.stringify(modelData)).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }
  
}
