import {Injectable} from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '../../../shared/HttpClient';
import 'rxjs/add/operator/map'
@Injectable()
export class ViewEzrfService {
    httpClient: HttpClient;
    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    showProgress(show: boolean) {
        this.httpClient.CheckForblockUI(show);
    }
    ezrfDetails = { requisitionId: '', section: 0 };
    getEzrfDetails(requisitionId: any, section: number): any {
        this.ezrfDetails = { requisitionId: requisitionId, section: section }
        return this.httpClient.postWithNoBlockUI('api/EzRfQuery/getEzrfDetails', JSON.stringify(this.ezrfDetails)).map(responsedata => {
            this.ezrfDetails = { requisitionId: '', section: 0 };
            return responsedata.json();
        });
    }

}