﻿import {GridLazyLoadArgs} from  '../../shared/global/ImsBase';
export class StudyFileSearchData extends GridLazyLoadArgs{
    studyId:string;
}
export class ManageStudyFiles {
    fileId:any;
    fileName :string;
    studyId :any;
    description :string;
    filePath :string;
    fileFormat :any;
    fileFormatName: string;
    fileSize :any;
    isShared: boolean;
    fileStatusId :any;
    fileStatus: string ;
    uploadedBy :any;
    roleId: any;
    uploadedUserName: string;
    uploadedOn :any;
    updatedBy :any;
    updatedUserName: string;
    updatedOn :any;
    rejectionReasonId :any;
    isPendingForApproval: boolean;
    isApproved:boolean;
    studyNumber: string;
    selectedRoles : string;
    selectedStudies : string;
}

export class StudyFileShareRequest {
    studyFileId:any;
    selectedRoles:any=[];
}

export class StudyFileUploadRequest {
    studyFileId: any;
    selectedStudies: any = [];
}

export class FileRejectionRequest
{
        studyFileId:any;
        rejectionReasonId:any;
}

export class SelectedItem {
    constructor(label, value) {
        this.label = label;
        this.value = value;
    }
    label: any;
    value:any;
}

export class StudyFileInfo {
    studyId: any;
    fileName: string;
    description: string;
    userId:any;
}