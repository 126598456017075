import { GridLazyLoadArgs } from "src/app/shared/global/ImsBase";


export class DTOStudyInvoiceCoverInfo {
    studyInvoiceId: any;
    studyInvoiceFor: any;
    invoiceStatusId: any;
    invoiceStatus: any;
    invoiceNumber: any;
    grandTotal: number;
    creditGrandTotal:number;
    oldInvoiceNumber:any;
    contractCurrencyCode:any;
    isAvailableForBulkApproval:any;
    studyInvoiceCoverInfo: DTOInvoiceBudgetSummaryView[] = [];
}


export class DTOInvoiceBudgetSummaryView  {
    invoiceBudgetItemId: any;
    studyInvoiceId: any;
    sourceScreenId: any;
    budgetItemText: any;
    budgetItemIndex: number;
    amountExcludingTotal: number;
    vatPercentage: number;
    vatAmount: number;
    annualInflationPercentage: number;
    withInflationAmount: number;
    discountPercentage: any;
    discountTotal: number;
    totalAmount: number;
    contractCurrency: any;
    creditAmountExcludingTotal:number;
    creditVatAmount: number;
    creditTotalAmount: number;
    withCreditInflationAmount: number;
}

export class DTOInvoiceStatusModel {
    studyInvoiceId: any;
    fromStatusId: any;
    toStatusId: any;
    toStatus: string;
    fromStatus: string;
    modifiedById: any;
    modifiedByName: string;
    modifiedOn: any;
    subjectString: string;
    toEmail: string;
    reasonForChange: string;
    modifiedOnDisplay: string;
    modifiedReason: string;
}

export class InvoiceStatusRequest {
    studyInvoiceId: any;
    fromStatusId: any;
    toStatus: string;
    QuotationStatus: string;
    toEmail: string;
    reasonForChange: string;
    subjectString: string;
}
