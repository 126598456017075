import {GridLazyLoadArgs} from  '../../../../shared/global/ImsBase'
export class StudyDataCaptureFieldSearchData extends GridLazyLoadArgs{
    studySetupVersionId:string;
}

export class StudyDataCaptureFieldsOrder{
    studySetupVersionId :any;    
    studyDataCaptureFields:StudyDataCaptureField[];
}

export class StudyDataCaptureField
{
    studyDataCaptureFieldId:any;
    dataCaptureFieldId:any;
    categoryId:any
    category:string;        
    field:string;    
    label:string;
    format:string;
    datacaptureFieldOrdinal:any;
    isDummy:any;
    isMandatory:any;
    order:number;    
}