import { GridLazyLoadArgs } from "src/app/shared/global/ImsBase";

export class CategoryList
{

categoryId:any;
categoryValue:any;

}

export class SaveFilesModel
{
    FilesModel:BudgetSummaryModel[] = [];
    InvoiceQuoteId: any;
}

export class ItemAutoCompleteModel{
    itemId:string;
    itemValue:string;
    studyQuoteId:string;
}
export class ItemSiteSearchArgs{
    itemId:string;
    itemValue:string;
    invoiceStudyId : string;
    isArgsValid:boolean=true;
    searchBy:number=null;
}

export class CategorySave{
    categoryId:string;
    itemId:string;
    studyQuoteId:string;
}

export class BudgetSummaryModel extends GridLazyLoadArgs {
   invoiceBudgetItemId :any;
   studyQuoteId :any;
   invoiceStudyId:any;
   sourceScreenId :any;
   budgetItemText : string ;
   budgetItemTotal: number;
   budgetItemIndex : number
   discount : number;
   vatPercentage : number;
   description:string;
   proposalCurrency:string;
   spentFetch:number;
   spentPTS:number;
   budgetToBurn:number
   budgetUsedPercent:number;
   plannedBudgetDiscount:any;
   finalPlannedBudget:number;
   applicableInflationPercent:number;
   plannedBudgetInflation:number;
}


