export class PhoneNumber {
    parentId: any;
    parentTypeId:any;
    phoneNumberId: any;
    countryCodeId: any;
    isPrimary: boolean = false;
    phoneNumber: string;
    phoneTypeId: any;

    phoneType: string;
    countryCode: string;

    // This is mainly to track the index against which the data has to be updated.
    recordCount:number;
}