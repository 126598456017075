import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '../../../shared/HttpClient';
import { Http, Response, Headers, Request, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import {IBreadCrumb} from '../Model/breadcrumb.model';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/catch';
@Injectable()
export class BreadCrumbService{
    public category:number;
    public id:string;
    public refreshWithParam:EventEmitter<any>=new EventEmitter<any>();
    public pushItemList:EventEmitter<any>=new EventEmitter<any>();
    public removeLastItem:EventEmitter<any>=new EventEmitter<any>();
     constructor(private httpClient: HttpClient) {
    }
    getBreadCrumb(category, typeId) {
        this.category = category;
        this.id= typeId;
        return this.httpClient.getWithNoBlockUI('api/breadcrumbquery/getBreadcrumb/' + category +"/"+typeId).map(responsedata => {
            var result = responsedata.json();
            return result;
        }); 
    }

}