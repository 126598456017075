import { Injectable } from "@angular/core";
import { HttpClient } from "src/app/shared/HttpClient";
import { ConfigService } from "src/app/config/ConfigService";
import { DtoCreateQuery, QueryModel } from "../../Models/Query.model";

@Injectable()
export class QueryCreationService{
    fetchApiUrl : string;
    queryMgmtApiUrl : string;

    constructor(private httpClient: HttpClient, config: ConfigService){
        this.fetchApiUrl = config.get('apiUrl');
        this.queryMgmtApiUrl = config.get('sampleQueryMgmtApiUrl');
    }

    blockUI(block: boolean) {
        this.httpClient.callBlockUI(block);
    }

    getOrderNumbers(searchText : string){        
        return this.httpClient.sampleQueryMgmtGet('api/QueryManagementQuery/GetOrderNumbersFromCaii/' + searchText).map(response => {
            let body = response.json();
            return body || {};
        });        
    }

    validateOrderNumber(searchText : string){        
        return this.httpClient.sampleQueryMgmtGetWithNoBlockUI('api/QueryManagementQuery/validateOrderNumber/' + searchText).map(response => {
            let body = response.json();
            return body || {};
        });        
    }

    createQuery(queryDetails: DtoCreateQuery, attachments: any[]) {
        let formData = new FormData()
        for (var i = 0; i < attachments.length; i++) {
            formData.append("Document", attachments[i]);
        }
        formData.append("queryData", JSON.stringify(queryDetails))

        return this.httpClient.sampleQueryMgmtPostWithXhr('api/QueryManagementQuery/createQuery', formData);        
    }

    CheckIfQueryLocked(queryHeaderId: QueryModel) {
        return this.httpClient.sampleQueryMgmtPost('api/QueryManagementQuery/checkIfLocked', JSON.stringify(queryHeaderId)).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        })
    }

    GetReasonCodes() {
        return this.httpClient.sampleQueryMgmtGet('api/QueryManagementQuery/getReasonCodes')
        .map(responsedata => {
            let body = responsedata.json()
            return body || {}
        }) 
    }
}