import { GridLazyLoadArgs } from "src/app/shared/global/ImsBase";

export class QueryModel {
    queryHeaderId: any;
    statusId: any;
    assignedTo: any;
    assignedUserId: any;
    primaryQueryId: any;
    secondaryQueryId: any;
    groupedQueryId: any;
    queryNumber: any;
    sla: any;
    rfNumber: any;
    isPrimary: any;
    orderNumber: any;
    modifiedBy: any;
    lab: any;
    studyNumber: any;
    siteCode: any;
    patientId: any;
    gender: any;
    visitCode: any;
    status: any;
    createdDate: any;
    createdTime: any;
    category: any;
    dateOfBirth: any;
    collectionDate: any;
    collectionTime: any;
    fastingStatus: any;
    awbNumber: any;
    subQueryNumbers: any;
    subQueryNumbersList: any;
    externalComments: any;
    internalComments: any;
    internalExternalComments: any
    isGroupedQuery: boolean;
    queryType: any;
    isDiscrepancy: any;
    sponsorProtocol: string;
    externalUserStatus: string;
    assignedExternalUserId: any;
    ethnicOrigin: any;
    isEscalated: boolean
    elapsedTimeFromCreation: number
    screeningNumber: any;
    dateOfBirthChecked: any;
    collectionTimeChecked: any;
    initialsChecked: any;
    genderChecked: any;
    collectionDateChecked: any;
    secondaryQueries: secondaryQueryDetails[] = [];
    reasonCodesComments:any;
}

export class secondaryQueryDetails{
    queryNumber: string
    orderNumber: string
}

export class queryDropdownModel {
    queryId: string
    queryNumber: string
    orderNumber: string
}

export class saveGroupQueriesRequest {
    primaryQueryId: string
    secondaryQueryIds: string[]
}

export class StudySLARequest {
    selectedStudyId: string[]
    selectedSLA: number
}

export class DropdownStudyNumberModel {
    key: string
    value: string
}

export class StudyBlindingConfigurationModel {
    studyId: string
    dateOfBirth: boolean
    gender: boolean
    initials: boolean
    collectionDate: boolean
    collectionTime: boolean
}
export class AttachmentsModel {
    queryDocumentId: string
    documentName: string
}

export class ExtraFilters extends GridLazyLoadArgs {
    isGroupedQuery: boolean;
    isCriticalAlert: boolean;
    isToxicityAlert: boolean;
    isManualQueryOnly: boolean;
    isRNR: boolean;
}

export class DtoCreateQuery {
    orderNumber: string
    studyNumber: string
    requisitionNo: string
    visit: string
    testCode: string
    siteCode: string
    awb: string
    category: string
    subjectId: string
    dateOfBirth: string
    fastingStatus: string
    ethnicOrigin: string
    gender: string
    collectionDate: string
    collectionTime: string
    receiptDate: string
    receiptTime: string
    statusId: string
    queryText: string
    screeningNo: string
    lab: string
    reasonCodesComments: any = ''
}

export class SaveQueryDetailsExtUserModel {
    queryHeaderId: string
    externalComments: string
    StatusToReplace: string
    constructor(queryHeaderId: string,
        externalComments: string,
        StatusToReplace: string) {
        this.queryHeaderId = queryHeaderId
        this.externalComments = externalComments
        this.StatusToReplace = StatusToReplace
    }
}

