import { GridLazyLoadArgs } from "src/app/shared/global/ImsBase";

export class Matrices{
    matrixId: any;
    name: string;
}
export class SystemMetadataRecords{
    systemMetaDataId: any;
    systemMetaDataType: string;
    shortValue: string;
    longValue: string;
    developmentValue: string;
}
export class SpecimenDetails{
    estSpecimenId: any;
    testId: any;
    matrices: Matrices = new Matrices();
    matrixNameToAdd: any;
    preferredShipmentConditionId: any;
    preferredShipmentCondition: SystemMetadataRecords = new SystemMetadataRecords();
    // collectionContainerId: any;
    // submissionContainerId: any;
    minimumVolume: number;
    assayVolume: number;
    preferredVolume: number;
    deadVolume: number;
    isDefault: number;
    instructionSet: InstructionSet[]= [];
    specimenStability: SpecimenStability[] = [];
    isDeatilsTouched: boolean ;
    isCollectionInstructionTouched: boolean ;
    isStabilityTouched: boolean ;
    updatePanelVolume: boolean;
}
export class InstructionSet{
    instructionTypeId: any;
    instruction: string;
}
export class SpecimenStability{
    value: number;
    measurementTypeId: any;
    specimenStabilityId: any;
    stabilityId: any;
    measurementType: SystemMetadataRecords = new SystemMetadataRecords();
    stability: SystemMetadataRecords = new SystemMetadataRecords();

}
export class MeasurementType{
    systemMetaDataType: string;
    text;
}