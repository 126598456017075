import { Http, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'
import { Injectable } from '@angular/core';
import { config } from 'rxjs';
import { ConfigService } from 'src/app/config/ConfigService';
import { SessionService } from 'src/app/shared/services/session.service';
import { RequestOptions } from '@angular/http';
import { HttpClient } from 'src/app/shared/HttpClient';

@Injectable()
export class BudgetSummaryService {

    httpClient: HttpClient;
    uploadImgUrl: any;
    apiurl:any;
    constructor(httpClient: HttpClient,config: ConfigService,private sessionService:SessionService) {
        this.httpClient = httpClient;
    }


    deleteBudgetSummary(invoiceStudyFileId: any): any {
        // alert(analysisFeesId);
        return this.httpClient.ezMoneyGet('api/InvoiceStudyQuery/deleteInvoiceStudyFile?invoiceStudyFileId='+ invoiceStudyFileId).map(responsedata => {
                let body = responsedata.json();
                return body || {};
            });
    }

    getBudgetSummaryGridList(searchData: any) {
        return this.httpClient.ezMoneyPost('api/StudyQuoteQuery/GetStudyQuoteBudgetSummaryMapView', JSON.stringify(searchData)).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }
  
    
    saveBudgetSummaryGridList(budgetsummaryModel: any): any {
        return this.httpClient.ezMoneyPost('api/StudyQuoteQuery/SaveStudyQuoteBudgetSummaryMapView', JSON.stringify(budgetsummaryModel)).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }

  
  
}