import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/catch';
import { HttpClient } from "src/app/shared/HttpClient";
import { ConfigService } from "src/app/config/ConfigService";
import { SessionService } from 'src/app/shared/services/session.service';
import { RequestOptions, ResponseContentType } from '@angular/http';
import { search } from 'core-js/fn/symbol';

@Injectable()
export class ListOfQueriesService {
    GetListOfStudies(loggedInUserId: any) {
        return this.httpClient.post('api/StudiesQuery/GetListOfStudiesExternalUser', JSON.stringify(loggedInUserId))
        .map(responsedata => {
            let body = responsedata.json()
            return body || {}
        })
    }
      

    url: any;
    studyUrl: any
    constructor(private httpClient: HttpClient, config: ConfigService, private sessionService: SessionService) {
        this.httpClient = httpClient;
        this.url = config.get('sampleQueryMgmtApiUrl');
        this.studyUrl = config.get('apiUrl');
    }

    blockUI(block: boolean) {
        this.httpClient.callBlockUI(block);
    }

    getHdlLink(){
        return this.httpClient.sampleQueryMgmtGet('api/QueryManagementQuery/hdlLink')
        .map(responsedata => {
            let body = responsedata.json()
            return body || {}
        })
    }
    processQueryDetails(queryHeaderId: any) {
        return this.httpClient.sampleQueryMgmtGet('api/QueryManagementQuery/processQueryDetails/'+queryHeaderId)
        .map(responsedata => {
            let body = responsedata.json()
            return body || {}
        })
    }
    processQueryDetailsExternal(queryHeaderId: any) {
        return this.httpClient.sampleQueryMgmtGet('api/QueryManagementQuery/processQueryDetailsExt/'+queryHeaderId)
        .map(responsedata => {
            let body = responsedata.json()
            return body || {}
        })
    }
    getQueryStatusBasedOnRole(): any {
        return this.httpClient.sampleQueryMgmtGetWithNoBlockUI('api/QueryManagementQuery/getQueryStatusBasedOnRole')
            .map(responsedata => {
                let body = responsedata.json()
                return body || {}
            })
    }

    getAllQueries(searchData: any): any {
        return this.httpClient.sampleQueryMgmtPost('api/QueryManagementQuery/queryTool', JSON.stringify(searchData)).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        })
    }

    downloadAllQueries(searchData:any): any {      
        return this.httpClient.sampleQueryMgmtPostWithResponseTypeBlob('api/QueryManagementQuery/downloadAllQueries', JSON.stringify(searchData)).map(responsedata=>{            
            var contentType =responsedata._body.type;
            var blob = new Blob([(<any>responsedata)._body], { type: contentType });
            
            return blob;
        });
    }

    downloadAllExternalQueries(searchData: any,studiesList :any): any {    
         var parameters = {'searchData' : searchData,
    'studiesList' : studiesList}  
        return this.httpClient.sampleQueryMgmtPostWithResponseTypeBlob('api/QueryManagementQuery/downloadAllExternalQueries', parameters).map(responsedata=>{            
            var contentType =responsedata._body.type;
            var blob = new Blob([(<any>responsedata)._body], { type: contentType });
            parameters = null;
            return blob;
        });
    }

    CheckIfQueryLocked(queryHeaderId: string) {
        return this.httpClient.sampleQueryMgmtPost('api/QueryManagementQuery/checkIfLocked', JSON.stringify(queryHeaderId)).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        })
    }

    getFlaggedQueries(searchData: any): any {
        return this.httpClient.sampleQueryMgmtPost('api/QueryManagementQuery/flaggedQueries', JSON.stringify(searchData)).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        })
    }

    getEscalatedCommExtQueries(searchData: any,studiesList :any): any {
        var parameters = {'searchData' : searchData,
    'studiesList' : studiesList}
  
        return this.httpClient.sampleQueryMgmtPost('api/QueryManagementQuery/queryToolExt', parameters).map(responsedata => {
            let body = responsedata.json()
            parameters = null;
            return body || {};
        })
    }

    CheckIfLockedExternal(queryHeaderId: any) {
        return this.httpClient.sampleQueryMgmtPost('api/QueryManagementQuery/checkIfLockedExternal', JSON.stringify(queryHeaderId)).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        })
    }
    LockProcess(selectedQueryToLock: any) {
        return this.httpClient.sampleQueryMgmtPost('api/QueryManagementQuery/lockProcess', JSON.stringify(selectedQueryToLock)).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        })
    }
    LockExternalProcess(selectedQueryToLock: any) {
        return this.httpClient.sampleQueryMgmtPost('api/QueryManagementQuery/lockExternalProcess', JSON.stringify(selectedQueryToLock)).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        })
    }
     GetUserName(userId: string) {
        return this.httpClient.sampleQueryMgmtPost('api/QueryManagementQuery/getUserName', JSON.stringify(userId)).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        })
    }

    getSecondaryQueryDetails(primaryQueryId : string): any {
        return this.httpClient.sampleQueryMgmtGet('api/QueryManagementQuery/getSecondaryQueryDetails/' + primaryQueryId)
            .map(responsedata => {
                let body = responsedata.json()
                return body || {}
            })
    }
    GetReasonCodes() {
        return this.httpClient.sampleQueryMgmtGet('api/QueryManagementQuery/getReasonCodes')
        .map(responsedata => {
            let body = responsedata.json()
            return body || {}
        }) 
    }
}
