import { Injectable } from "@angular/core";
import { HttpClient } from "../../../../shared/HttpClient";
import { ConfigService } from "../../../../config/ConfigService";

@Injectable()
export class CreateBOMService {
    httpClient: HttpClient;
    apiUrl: any;
    constructor(httpClient: HttpClient, config: ConfigService) {
        this.httpClient = httpClient;
        this.apiUrl = config.get('apiUrl');
    }
     blockUI(blocked: boolean) {
        this.httpClient.callBlockUI(blocked);
    }
    getAllBomHeaders(searchData: any): any {
        return this.httpClient.post('api/KitPackingQuery/getAllBomHeaders', JSON.stringify(searchData)).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        })
    }
    getBomStatusList(): any {
        return this.httpClient.getWithNoBlockUI('api/KitPackingQuery/getBomStatusList').map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }
}