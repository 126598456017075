import { GridLazyLoadArgs } from "src/app/shared/global/ImsBase";




export class StudyQuoteOtherItemMapModel extends GridLazyLoadArgs {

    StudyQuoteOtherId: any;
    StudyQuoteId: any;
    StudyQuoteOtherItemId: any;
    QuotedItemId: any;
    ItemName: string;
    QuotedItemName:string;
    UnitPrice: any;
    UnitCurrencyId: any;
    UnitCurrencyName: string;
}

export class DTOStudyInvoiceStartupModel extends GridLazyLoadArgs {

     studyInvoiceStartupId: any;
     studyInvoiceId:any;
    invoiceLocalStudyId :any;
    quotedItemId:any;
    executingLabId:any;
    executingLabName:string;
    itemName:string;
    noOfUnits : number;
    unitPrice:any;
    unitCurrencyId :any;
    unitCurrencyName:string;
    unitCurrencyTotal:any;
     contracyCurrencyTotal :any;
     grandTotal:number = 0;
     comment:any;
}


export class DTOStudyInvoiceUserEntryModel
{
     studyInvoiceId:string;
     systemPrice :any;
     studyInvoiceUserEntryItemName:any;
     studyInvoiceUserEntryItemId :any;
     dTOStudyInvoiceStartupModels: DTOStudyInvoiceStartupModel[] =[];
}


export class DTOInvoiceStudyLaboratory
{
    invoiceStudyLaboratoryId :any;
    invoiceStudyId :any;
    invoiceStudyLaboratoryCode :string;
    invoiceStudyLaboratoryName :string;
}

export class DTOProposedCurrency {
    CurrencyId: any;
    CurrencyValue: string;
}

export class   DTOCurrencyFactor{
    contractCurrencyId:any;
    unitCurrencyId:any;
    unitCurrencyName: any;
    conversionFactorToContractCurrency: any;
}


export class DTOStudyInvoiceAutoCompleteItem
{
     autoCompleteItemName: string;
     autoCompleteItemId:any;
     systemPrice:number;

}
export class StudyOtherModel extends GridLazyLoadArgs{
    studyQuoteOtherId: any;
    invoiceStudyId:any;
    studyInvoiceId:any;
    studyQuoteId: any;
    studyQuoteOtherItemId: any;
    quotedItemGroupId: any;
    quotedItemId: any;
    itemSourceId:any;
    itemName: string;
    unitPrice: number;
    unitCurrencyId: any;
    unitCurrencyName: string;
    executingLabName: string;
    executingLabId: any;
    recurrenceId: any;
    recurrenceType: string;
    comment: any;
}
export class DTOCurrency {
    currencyId: any;
    currencyValue: string;
}

export class DTORecurrence{
    recurrenceId: any;
    recurrenceType: string;
}




