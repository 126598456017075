import {Injectable} from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '../../../shared/HttpClient';
import 'rxjs/add/operator/map'

@Injectable()
export class StudySetupVersionService {
      httpClient: HttpClient;
    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    getStudySetupVersions(studySetupId: any){
            return this.httpClient.get('api/StudySetupsQuery/GetAllStudySetupVersions?studySetupId=' + studySetupId).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }
    getStudySetupVersionStatusLogs(setupVersionId:any){
        return this.httpClient.get('api/StudySetupsQuery/getStatus?studySetupVersionId=' + setupVersionId).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }
}

