import { Injectable } from '@angular/core';
import { Http, Response, Headers, Request, RequestOptions, URLSearchParams} from '@angular/http';
import { Observable }     from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/catch';
import { HttpClient } from '../../../shared/HttpClient';
import { ImsBase } from "../../../shared/global/ImsBase";
import { ConfigService } from '../../../config/ConfigService';
@Injectable()
export class RfStatusService {
    httpClient: HttpClient;
    apiUrl: any;
    constructor(httpClient: HttpClient, config: ConfigService) {
        this.httpClient = httpClient;
        this.apiUrl = config.get('apiUrl');
    }

    getRfBulkStatusRequests(searchData:any): any {      
        return this.httpClient.post('api/OrdersQuery/getBulkRfDownloadRequests', JSON.stringify(searchData)).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }
    getPrintRFStatusForOrder(orderId: any) {
        return this.httpClient.get('api/OrdersQuery/getPrintRFStatusForOrder/' + orderId)
            .map(responsedata => {
                let body = responsedata.json();
                return body || {};
            });
    }

    downloadBulkRf(printjobId: any) {

        return this.httpClient.getWithResponseTypeBlob('api/OrdersQuery/downloadBulkRf/' + printjobId)
        .map(responsedata => {
            if (responsedata.status == 400) {
                return "FAILURE";
            } else if (responsedata.status == 200) {
                var fileName = "bulkRfFile";
                var contentType = 'application/octet-stream';
                var contentDisposition  = responsedata.headers.get('Content-Disposition');
                const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = fileNameRegex.exec(contentDisposition);
                if (matches != null && matches[1]) {
                    fileName = matches[1].replace(/['"]/g, '');
                }
                var blob = new Blob([(<any>responsedata)._body], { type: contentType });
                var blobDatawithFileName = {"fileName" : fileName, "blobData": blob}
                return blobDatawithFileName;
            }
        });
    }

    printConsolidatedRf(printJobId:any){
        return this.httpClient.post('api/OrdersQuery/sendConsolidatedRfToPrinter/', JSON.stringify(printJobId)).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }
}