export class Address {
    addressId: any;
    parentId: any;
    parentTypeId:any;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    street: string;
    city: string;
    pinCode: string;
    state: string;
    country: string;
    addressCountryId: any;

    // This is mainly to track the index against which the data has to be updated.
    recordCount: number;
}