import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import { Http, Headers, Request, RequestOptions, Response } from '@angular/http';
import { HttpClient } from '../../shared/HttpClient';


@Injectable()
export class SystemMetaDataService {
    httpClient: HttpClient;
    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;  
    }
    
    getSystemMetaDataGridData(searchData){
       return this.httpClient.post('api/SystemMetaData/getSystemMetaDataGridData',JSON.stringify(searchData)).map(responsedata => {
        let body = responsedata.json();
        return body || {};
    }); 
   }

}