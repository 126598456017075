import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/catch';
import { HttpClient } from '../../../shared/HttpClient';
import { ImsBase } from "../../../shared/global/ImsBase";
import { ConfigService } from '../../../config/ConfigService';

@Injectable()
export class StudyReportsService {
    httpClient: HttpClient;

    apiUrl: any;
    constructor(httpClient: HttpClient, config: ConfigService) {
        this.httpClient = httpClient;
        this.apiUrl = config.get('apiUrl');
    }

    getStudiesAndSitesForReportFilter(studyNumber: string): any {
        return this.httpClient.post('api/StudyReportsQuery/getStudiesAndSitesForReportFilter', JSON.stringify(studyNumber)).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }

    getReportTypes(): any {
        return this.httpClient.get('api/StudyReportsQuery/getReportTypes').map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }

    getStudyReports(searchData: any): any {
        return this.httpClient.post('api/StudyReportsQuery/getStudyReports', JSON.stringify(searchData)).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }
    searchSitesByStudy = { studyId: '', siteCode: '', checkResultsAccess: false, checkInterLabAccess: false, checkReportAccess: true };
    getStudySitesForSelectedStudy(studyId: any, siteCode: string): any {
        this.searchSitesByStudy = { studyId: studyId, siteCode: siteCode, checkResultsAccess: false, checkInterLabAccess: false, checkReportAccess: true };
        return this.httpClient.post('api/StudyReportsQuery/getSitesForSelectedStudy', this.searchSitesByStudy).map(responsedata => {
            let body = responsedata.json();
            this.searchSitesByStudy = { studyId: '', siteCode: '', checkResultsAccess: false, checkInterLabAccess: false, checkReportAccess: true };
            return body || {};
        });
    }
    isUserAllowedToSwitchViewMode(studyId: any): any {
        return this.httpClient.post('api/StudyReportsQuery/is-user-allowed-to-switch-viewmode', JSON.stringify(studyId)).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }

    // getAllowedSitesForUser(roleId) : any {
    //     return this.httpClient.get('api/StudyReportsQuery/GetAllowedSitesForUser?roleId=' + roleId).map(responsedata => {
    //         let body = responsedata.json();
    //         return body || {};
    //     });        
    // }

    // downloadFile(reportId:any){     
    //     const header = new Headers();
    //     let options = new RequestOptions({ headers: header });
    //     window.open(this.apiUrl + "api/StudyReportsQuery/downloadstudyreport/" + reportId);
    // }

    downloadFile(lobRefId: any, userId: any, studyReportId: any, studyId: any): any {
        return this.httpClient.getWithResponseTypeBlob('api/StudyReportsQuery/downloadstudyreportfetch/' + studyReportId + '/' + lobRefId + '/' + studyId).map(responsedata => {
            if (responsedata.status === 200) {
                const blobURL = window.URL.createObjectURL(responsedata._body);
                window.open(blobURL, this.getDate());
            }
            return responsedata || {};
        });
    }

    hideUnhideStudyReport(studyReportsModel: any): any {
        return this.httpClient.post('api/StudyReportsQuery/hideUnHideStudyReport', JSON.stringify(studyReportsModel)).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }

    getStudyReportViewers(studyReportId: any) {
        return this.httpClient.get('api/StudyReportsQuery/getStudyReportViewers/' + studyReportId).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }

    OpenWindowWithPost(url, name, params) {
        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", url);
        form.setAttribute("target", name);

        for (var i in params) {
            if (params.hasOwnProperty(i)) {
                var input = document.createElement('input');
                input.type = 'hidden';
                input.name = i;
                input.value = params[i];
                form.appendChild(input);
            }
        }

        document.body.appendChild(form);

        window.open(this.apiUrl + "api/StudyReportsQuery/downloadstudyreport", name);

        form.submit();

        document.body.removeChild(form);
    }
    getDate() {
        var monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
            "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
        ];

        var date = new Date()
        //date.setDate(date.getDate() - 1)
        var time = this.getClockTime(date);
        var strDate = date.getDate() +
            //"-"+
            monthNames[date.getMonth()] +
            //"-"+
            date.getFullYear() +
            ', ' +
            time;
        return strDate;
    }
    getClockTime(now) {

        var hour = now.getHours();
        var minute = now.getMinutes();
        var second = now.getSeconds();
        var ap = "AM";
        if (hour > 11) { ap = "PM"; }
        if (hour > 12) { hour = hour - 12; }
        if (hour == 0) { hour = 12; }
        if (hour < 10) { hour = "0" + hour; }
        if (minute < 10) { minute = "0" + minute; }
        if (second < 10) { second = "0" + second; }
        var timeString = hour + '-' + minute + '-' + second + " " + ap;
        return timeString;
    }
}