import { Injectable } from '@angular/core';
import { SelectItem } from 'primeng/primeng';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { StudyPrimingQCStatus } from 'src/app/shared/globals';
import { HttpClient } from '../../shared/HttpClient';

@Injectable()
export class MasterDataService {
  userPermissions: any

  constructor(private httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  ChangeQCStatus(qcStatus): any {
    return this.httpClient.post('api/study-priming/ChangeQCStatus', JSON.stringify(qcStatus)).map(responsedata => {
      let body = responsedata.json();
      return body || {};
    });
  }

  downloadMasterData(masterSequence) {
    return this.httpClient.getWithResponseTypeBlob("api/study-priming-query/generateMasterData/"+masterSequence)
      .map(responsedata => {
        if (responsedata.status == 400) {
          return "FAILURE";
        } else if (responsedata.status == 200) {
          var contentType = responsedata._body.type;
          var blob = new Blob([(<any>responsedata)._body], { type: contentType });
          return blob;
        }
      });
  }

  getStatusList() {
    let statusList: SelectItem[] = [];
    statusList.push({ label: 'All', value: 'All' });
    statusList.push({ label: StudyPrimingQCStatus.Draft, value: StudyPrimingQCStatus.Draft });
    statusList.push({ label: StudyPrimingQCStatus.Active, value: StudyPrimingQCStatus.Active });
    statusList.push({ label: StudyPrimingQCStatus.QCRejected, value: StudyPrimingQCStatus.QCRejected });
    statusList.push({ label: StudyPrimingQCStatus.In_Active, value: StudyPrimingQCStatus.In_Active });
    return statusList;
  }
}
