import { GridLazyLoadArgs } from "src/app/shared/global/ImsBase";


export class DTOStudyInvoiceKitList {
    studyInvoiceKitView: DTOStudyInvoiceKitView[] = [];
}

export class DTOStudyInvoiceKitView extends GridLazyLoadArgs {
    itemName: any
    executingLabId: any;
    executingLabName: any;
    contractCurrencyId: any;
    contractCurrencyName: any;
    contractCurrencyTotal: number;
    studyInvoiceId: any;
}
export class DTOStudyInvoiceKitItemModel {
    studyInvoiceId: any;
    studyInvoiceKitItemViewModel: DTOStudyInvoiceKitItemViewModel[] = [];
}
export class DTOStudyInvoiceKitItemViewModel extends GridLazyLoadArgs {
    invoicingCyclesToConsider: number;
    studyInvoiceKitItemId: any;
    externalStudyInvoiceKitItemId: any;
    studyInvoiceId: any;
    invoiceLocalStudyId: any;
    itemSourceId: any;
    invoiceStudyId; any;
    invoiceCycleId: any;
    invoiceCycleDisplay: any;
    quotedItemId: any;
    excludeFromInvoice: boolean;
    invoiceLocalStudyNumber: any;
    orderNumber: any;
    departmentName: any;
    kitDescription: any;
    centerCode: any;
    inQuote: boolean;
    charged: any;
    shipDate: any;
    itemType: any;
    articleNumber: any;
    supplyItem: any;
    quantity: any;
    unitPrice: number;
    madeTrueNow:boolean;
    total: number;
    comment:any;
    contractCurrencyTotal:number;
    currencyId:any;
}
export class DTOStudyInvoiceKitModel extends GridLazyLoadArgs {

    studyInvoiceKitId: any;
    studyInvoiceId: any;
    invoiceLocalStudyId: any;
    quotedItemId: any;
    executingLabId: any;
    executingLabName: string;
    itemName: string;
    noOfUnits: number;
    unitPrice: number;
    unitCurrencyId: any;
    unitCurrencyName: string;
    unitCurrencyTotal: number;
    contracyCurrencyTotal: number;
    comment:any;
}
export class DTOStudyInvoiceKitUserEntryModel {
    studyInvoiceId: string;
    systemPrice: any;
    studyInvoiceUserEntryItemName: any;
    studyInvoiceUserEntryItemId: any;
    invoiceLocalStudyId: any;
    unitCurrencyId : any;
    unitCurrencyTotal:any;
     contracyCurrencyTotal :any;
    dTOStudyInvoiceKitModels: DTOStudyInvoiceKitModel[] = [];
    localStudyLabDisplay: string;
    executingLabId: any;
}
export class DTOInvoiceStudyLaboratory {
    invoiceStudyLaboratoryId: any;
    invoiceStudyId: any;
    invoiceStudyLaboratoryCode: string;
    invoiceStudyLaboratoryName: string;
}


export class DTOProposedCurrency {
    CurrencyId: any;
    CurrencyValue: string;
}
export class DTOCurrencyFactor {
    relativeInvoicingCycleNumber: any;
    invoicingCycleId: any;
    contractCurrencyId: any;
    unitCurrencyId: any;
    unitCurrencyName: any;
    conversionFactorToContractCurrency: any;
}
