import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/catch';
import { HttpClient } from '../../../shared/HttpClient';
import { ImsBase } from "../../../shared/global/ImsBase";
@Injectable()
export class LabelPrintingService {
    httpClient: HttpClient;
    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    getRequisitionSequence(requsisitonNo): any {
        return this.httpClient.get('api/requisitonsQuery/getRequisitionSequence?requisitionNo=' + encodeURIComponent(requsisitonNo)).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }

    getWorkStation(workStation): any {
        return this.httpClient.get('api/requisitonsQuery/getPrinters/' + encodeURIComponent(workStation)).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }

    getAssignedWorkStation(): any {
        return this.httpClient.get('api/requisitonsQuery/getAssignedWorkStation').map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }

    assignWorkStation(printerConfigId: string) {
        var apiPath = 'api/requisitonsQuery/assignPrinterWorkStation/' + printerConfigId;
        return this.httpClient.post(apiPath, printerConfigId).map(responsedata => {
            let body = responsedata.json();
            return body || [];
        });
    }

    printKitTubeLabels(model: any) {
        var apiPath = 'api/requisitonsQuery/printKitTubeLabels/';
        return this.httpClient.post(apiPath, JSON.stringify(model)).map(responsedata => {
            let body = responsedata.json();
            return body || [];
        });
    }

    printTubeLabel(requisitionNo: string, visitSpecimenId: string, printerConfigId: string) {
        var apiPath = 'api/requisitonsQuery/printTubeLabel/' + requisitionNo + "/" + visitSpecimenId + "/" + printerConfigId;
        return this.httpClient.post(apiPath, requisitionNo + "/" + visitSpecimenId + "/" + printerConfigId).map(responsedata => {
            let body = responsedata.json();
            return body || [];
        });
    }

  printKitBoxLabel(requisitionNo: string, printerConfigId: string, expiryDate: string, isExpiryDateValueFromPopup: boolean) {
    var apiPath = 'api/requisitonsQuery/printKitBoxLabel/' + requisitionNo + "/" + printerConfigId + "/" + expiryDate + "/" + isExpiryDateValueFromPopup
    return this.httpClient.post(apiPath, requisitionNo + "/" + printerConfigId + "/" + expiryDate + "/" + isExpiryDateValueFromPopup).map(responsedata => {
            let body = responsedata.json();
            return body || [];
        });
    }

    printAllTubeLabels(requisitionNo: string, printerConfigId: string, isAliquot: boolean) {
        var apiPath = 'api/requisitonsQuery/printAllTubeLabels/' + requisitionNo + "/" + printerConfigId + "/" + isAliquot;
        return this.httpClient.post(apiPath, requisitionNo + "/" + printerConfigId + "/" + isAliquot).map(responsedata => {
            let body = responsedata.json();
            return body || [];
        });
    }

    printAllIsolateTubeLabels(requisitionNo: string, printerConfigId: string) {
        var apiPath = 'api/requisitonsQuery/printAllIsolateTubeLabels/' + requisitionNo + "/" + printerConfigId;
        return this.httpClient.post(apiPath, requisitionNo + "/" + printerConfigId).map(responsedata => {
            let body = responsedata.json();
            return body || [];
        });
    }

    printSpareLabels(requisitionNo: string, spareLabelCount: number, printerConfigId: string) {
        var apiPath = 'api/requisitonsQuery/printSpareLabels/' + requisitionNo + "/" + spareLabelCount + "/" + printerConfigId;
        return this.httpClient.post(apiPath, requisitionNo + "/" + spareLabelCount + "/" + printerConfigId).map(responsedata => {
            let body = responsedata.json();
            return body || [];
        });
    }

    printAddressLabel(requisitionNo: string, addrLabelCount: number,printerConfigId: string) {
        var apiPath = 'api/requisitonsQuery/printAddressLabel/' + requisitionNo + "/" +  addrLabelCount + "/" +  printerConfigId;
        return this.httpClient.post(apiPath, requisitionNo + "/" + addrLabelCount + "/" + printerConfigId).map(responsedata => {
            let body = responsedata.json();
            return body || [];
        });
    }

    printIsolateTubeLabel(requisitionNo: string, visitSpecimenId: string, isolateVisitSpecimenId: string, printerConfigId: string) {
        var apiPath = 'api/requisitonsQuery/printIsolateTubeLabel/' + requisitionNo + "/" + visitSpecimenId + "/" + isolateVisitSpecimenId + "/" + printerConfigId;
        return this.httpClient.post(apiPath, requisitionNo + "/" + visitSpecimenId + "/" + isolateVisitSpecimenId + "/" + printerConfigId).map(responsedata => {
            let body = responsedata.json();
            return body || [];
        });
    }

    updateKitExpiryDate(expiryDate: string, orderId: string, requisitionNo: string) {
        var apiPath = 'api/requisitonsQuery/updateKitExpiryDate/' + expiryDate + "/" + orderId + "/" + requisitionNo;
        return this.httpClient.postWithNoBlockUI(apiPath, expiryDate + "/" + orderId + "/" + requisitionNo).map(responseData => {
            let body = responseData.json();
            return body || [];
        })
    }
    checkPrintRequestExists(requisitionNo: string, labelType:number,orderId:any) {
        var apiPath = 'api/requisitonsQuery/checkRequisitionLabelPrinted/' + requisitionNo + "/" + labelType+"/"+orderId;
        return this.httpClient.post(apiPath, requisitionNo + "/" + labelType+"/"+orderId ).map(responsedata => {
            let body = responsedata.json();
            return body || [];
        });
    }
}
