import { Injectable } from '@angular/core';
import { HttpClient } from '../../../shared/HttpClient';

@Injectable({
    providedIn: 'root'
})
export class ScanSamplesService {

    httpClient: HttpClient;
    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    getLabOrderSamplesByRequisitionId(requistionId: string): any {
        return this.httpClient.get('api/sample-requisition-query/GetLabOrderSamplesByRequisitionId/' + requistionId).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }

    updateScannedRequisitionSamples(scannedSamplesListByLabOrderId: any): any {
        return this.httpClient.post('api/sample-requisition/UpdateScannedRequisitionSamples/',
            JSON.stringify({ "ScannedRequisitionSamples": scannedSamplesListByLabOrderId }))
            .map(responsedata => {
                let body = responsedata.json();
                return body || {};
            });
    }

    isRequisitionFetchGenerated(requistionId: string): any {
        return this.httpClient.get('api/sample-requisition-query/requisition/is-system-generated?requisitionNumberWithSequence=' + requistionId).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }

    printTubeLabel(primingInfo: any) {
        var apiPath = 'api/sample-requisition/print-tube-label';
        return this.httpClient.post(apiPath, primingInfo).map(responseData => {
            let body = responseData.json();
            return body || [];
        })
    }

    printMedlimsTubeLabel(primingInfo: any) {
        var apiPath = 'api/sample-requisition/print-medlims-tube-label';
        return this.httpClient.post(apiPath, primingInfo).map(responseData => {
            let body = responseData.json();
            return body || [];
        })
    }

    getSampleRequsitionIdBySampleInfo(sampleId: string): any {
        return this.httpClient.get('api/samplemanagement/GetSampleRequsitionIdBySampleInfo/' + sampleId).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }

    printFormLabel(printInfo: any) {
        var apiPath = 'api/sample-requisition/print-form-label';
        return this.httpClient.post(apiPath, printInfo).map(res => {
            let body = res.json();
            return body || {};
        })
    }

    printAllLabelsInRf(printInfo: any) {
        var apiPath = 'api/sample-requisition/print-all-labels';
        return this.httpClient.post(apiPath, printInfo).map(res => {
            let body = res.json();
            return body || {};
        });
    }

    getSampleDetails(model: any) {
        var apiPath = 'api/sample-requisition/getAdditionalSampleDetails';
        return this.httpClient.post(apiPath, model).map(response => {
            let body = response.json();
            return body || {};
        });
    }

    updateCustomBarCode(updateCustomBarCodeInput: any): any {
        return this.httpClient.post('api/sample-requisition/UpdateCustomBarCode/',
            JSON.stringify(updateCustomBarCodeInput))
            .map(responsedata => {
                let body = responsedata.json();
                return body || {};
            });
    }
    goToSimsCheckIn(){
        window.open(this.httpClient.simsUrl+"#/home/sampleManagement/check-in", "_blank");

    }

    updateSampleAsNotReceived(samplesHybridMappingId: string): any {
        return this.httpClient.get('api/sample-requisition-query/UpdateSampleAsNotReceived/' + samplesHybridMappingId).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }
}


