import { Injectable } from '@angular/core';
import { HttpClient } from '../../../shared/HttpClient';
import { Observable } from 'rxjs/Observable';
//import * as Globals from "../../shared/globals";
import { ProductDetailsModel } from '../Model/productdetails.model';
import { ConfigService } from '../../../config/ConfigService';

@Injectable()
export class ProductDetailsService {
    httpClient: HttpClient;
    
    apiUrl:any;
    eComBaseUrl: string;
    constructor( httpClient: HttpClient, config: ConfigService) {
        this.httpClient = httpClient;
        this.apiUrl = config.get('apiUrl');
        this.eComBaseUrl = config.get('ecomApiUrl');
    }



    getProductsDetails(productId : any,siteId:string) : any {
        return this.httpClient.eComGet('api/productOrderQuery/getProductsDetails?productId=' + productId +'&siteId='+siteId).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });        
    }

    // getProductsDetails(productId:any,siteId : any): any {      
    //     return this.httpClient.eComGet('api/productOrderQuery/getProductsDetails?productId='+ productId +'&siteId='+ siteId ).map(responsedata => {
    //         let body = responsedata.json();
    //         return body || {};
    //     });
    // }

    getProducts(searchData:any): any {      
        return this.httpClient.eComPost('api/productOrderQuery/getProducts', JSON.stringify(searchData)).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }
    getCategoryList():any {
        return this.httpClient.eComGet('api/productOrderQuery/getCategoryList').map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }
    downloadProductFiles(fileId: any) {
        return this.httpClient.eComGetWithResponseTypeBlob('api/productOrderQuery/downloadProductFiles/' + fileId)
            .map(responsedata => {
                if (responsedata.status == 400) {
                    return "FAILURE";
                } else if (responsedata.status == 200) {
                    var contentType = 'application/pdf';
                    var blob = new Blob([(<any>responsedata)._body], { type: contentType });
                    return blob;
                }
            });
    }
    
}
