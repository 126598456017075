export class PrinterConfiguration{
        printerConfigId:string;
        WorkStation:string;
        TubeLabelPrinter:string;
        BoxLabelPrinter:string;
        RFPrinter:string;
        QCDocPrinter:string;
        AddressLabelPrinter:string;     
        printerLock: boolean;
        lockUserLogin: string;
        CustomTubeLabelPrinter:string;

}
