import {Injectable} from '@angular/core';
import { Http, Headers, Response, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '../../shared/HttpClient';
import { ConfigService } from '../../config/ConfigService';
 import { TestManagementSearchData,TestManagementModel } from "../Model/testManagementDetails.model";
import 'rxjs/add/operator/map';
import { SessionService } from '../../shared/services/session.service';
@Injectable()
export class TestManagementService {
      httpClient: HttpClient;
      //uploadImgUrl: any;
      
    constructor(httpClient: HttpClient, config:ConfigService,private sessionService:SessionService) {
        this.httpClient = httpClient;
       // this.uploadImgUrl = config.get('apiUrl');
    }
    blockUI(blocked:boolean){
        this.httpClient.callBlockUI(blocked);
    }
 
    saveTest(testDetails:TestManagementModel):any{
        return this.httpClient.cataloguePost('api/TestManagement/saveTest', JSON.stringify(testDetails)).map(responsedata => {
                  let body = responsedata.json();
                  return body || {};
             }); 
    }

    

    getGridData(searchData){
         return this.httpClient.cataloguePost('api/TestManagement/getTestManagementGridData', JSON.stringify(searchData)).map(responsedata => {
              let body = responsedata.json();
              return body || {};
         }); 
    }

    downloadExcel(searchData)
    {
        //  return this.httpClient.post('api/TestManagement/GetTestManagementExcelExtract', JSON.stringify(searchData)).map(responsedata => {
        //           let body = responsedata.json();
        //           return body || {};
        //      }); 

        return this.httpClient.cataloguePostWithResponseTypeBlob('api/TestManagement/GetTestManagementExcelExtract', JSON.stringify(searchData))
        .map(responsedata => {
            if(responsedata.status == 400){
                return "FAILURE";               
            } else if(responsedata.status == 200){
                var contentType =responsedata._body.type;
            var blob = new Blob([(<any>responsedata)._body], { type: contentType });
            return blob;
            }            
        });

        // return this.httpClient.post('api/TestManagement/GetTestManagementExcelExtract',JSON.stringify(searchData))
        // .map(responsedata => {
        //     if(responsedata.status == 400){
        //         return "FAILURE";               
        //     } else if(responsedata.status == 200){
        //         var contentType =responsedata._body.type;
        //     var blob = new Blob([(<any>responsedata)._body], { type: contentType });
        //     return blob;
        //     }            
        // });
    }

    isTestAlreadyExists(searchData){
         return this.httpClient.cataloguePost('api/TestManagement/isTestAlreadyExists', JSON.stringify(searchData)).map(responsedata => {
              let body = responsedata.json();
              return body || {};
         }); 
    }

    removeTest(testId:any):any{
        return this.httpClient.catalogueGet('api/TestManagement/deleteTest/'+ testId).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }
}

