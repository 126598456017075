import { Injectable } from '@angular/core';
import { HttpClient } from '../../shared/HttpClient';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/catch';


@Injectable()
export class CtmService {

    constructor(private httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    /*
Gets all the studies whose global study number contains the entered global study number key.
The method only gets top 4 records which will be shown to the user as suggestion.
**/
    getMatchingGlobalStudyNumbers(searchStudies: string): any {
        return this.httpClient.get('api/ctm/getMatchingStudyNumbers?searchText=' + searchStudies).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }

    getStudy(searchText) {
        if (searchText == undefined || searchText == null) {
            searchText = '';
        }
        return this.httpClient.get('api/ctm/GetStudy?&globalStudyNumber=' + searchText).map(responsedata => {
            return responsedata.json();
        });
    }

    getStudySites(studyId: string): any {
        return this.httpClient.get('api/ctm/ctm-study-sites/' + studyId).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }

    getCtmRoles(): any {
        return this.httpClient.get('api/ctm/ctm-roles').map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }

    getCtmInternalEmails(data: string): any {
        return this.httpClient.post('api/ctm/ctm-internal-emails/', data).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }

    getCtmExternalEmails(data: any): any {
        return this.httpClient.post('api/ctm/ctm-external-emails/', data).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }

    getKpFacility(): any{
        return this.httpClient.get('api/ctm/ctm-KpFacility').map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }

    saveCtm(data: any): any {
        return this.httpClient.post('api/ctm/ctm-save', data).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }

    updateCtm(data: any): any {
        return this.httpClient.post('api/ctm/ctm-update', data).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }

    getlAllCtmData(searchData: any) {
        return this.httpClient.post('api/ctm/getAllCtm', searchData).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        })
    }

    getCtmDetail(ctmConfigId: string): any {
        return this.httpClient.get('api/ctm/ctm-detail/' + ctmConfigId).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }

    exportCTMConfigurationToExcel(searchData: any) {
        return this.httpClient.postWithResponseTypeBlob("api/ctm/ctm-export", JSON.stringify(searchData))
            .map(responsedata => {
                if (responsedata.status == 400) {
                    return "FAILURE";
                } else if (responsedata.status == 200) {
                    var contentType = responsedata._body.type;
                    var blob = new Blob([(<any>responsedata)._body], { type: contentType });
                    return blob;
                }
            });
    }

    getLocalStudyNumbers(data:any): any {
        return this.httpClient.post('api/ctm/common-lsn/',data).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }
    getCtmAuditDetail(ctmConfigId: string): any {
        return this.httpClient.get('api/ctm/ctm-audit/' + ctmConfigId).map(responsedata => {
            let body = responsedata.json();
            return body || {};
        });
    }


}
